import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonInput,
  IonRow,
  IonCol,
  IonButton,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { useState } from "react";

function EmailSupportForm() {
  const [present, dismiss] = useIonLoading();
  const [values, setValues] = useState({
    technology: "Encrypted Email",
    issue: "",
    contact: "",
    name: "",
    email: "",
    phone: "",
    contactTime: "",
  });

  const pushValues = (msg: string, value: string) => {
    setValues({ ...values, [msg]: value });
    console.log(msg);
  };

  async function _sendData(): Promise<any> {

    fetch(
      `https://prod-03.westus2.logic.azure.com:443/workflows/e53728eb2c2d4f67b4320ea90dd019b2/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Ke78hk3KBoanIOL-ZRZEuPrhANlRjUh38ksASgXh5g4`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values)        
      }
    )
      .then((res) => res.json())
      .then(function (data) {
        debugger;
        setValues({
          technology: "Encrypted Email",
          issue: "",
          contact: "",
          name: "",
          email: "",
          phone: "",
          contactTime: "",
        });
        dismiss();
        presentAlert({
          header: "Windhorse Community Services",
          subHeader: "Support form submitted",
          message: "Thank you. WCS support will be in touch shortly.",
          buttons: [{
            text: "OK",
            handler: () => {
              window.location.reload()
            }
        }],
        })                
      })
           
      .catch((error) => {
        presentAlert({
          header: "An error occured",
          subHeader: "Windhorse Community Services",
          message: "We apologize, an error occured. Please contact support@wcsboulder.com directly." + {error},
          buttons: ["OK"],
        });
      })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    present({
      message: 'submitting form to WCS support...'
    })
      _sendData();
  };
  const [presentAlert] = useIonAlert();
  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* <IonItem lines="full">
          <IonLabel position="floating">Please select a technology</IonLabel>
          <IonSelect onIonChange={(e) => pushValues("technology", `${e.detail.value}`)}>
            <IonSelectOption value="email">Encrypted Email</IonSelectOption>
            <IonSelectOption value="teams">Microsoft Teams</IonSelectOption>
            <IonSelectOption value="sharepoint">SharePoint</IonSelectOption>
          </IonSelect>
        </IonItem> */}

        <IonItem>
          <IonLabel>How can we help?</IonLabel>
          <IonTextarea
            autoGrow={true}
            value={values.issue}
            onIonChange={(e) => pushValues("issue", `${e.detail.value}`)}
          ></IonTextarea>
        </IonItem>

        <IonItem lines="full">
          <IonLabel position="floating">How may we contact you?</IonLabel>
          <IonSelect value={values.contact}
            onIonChange={(e) => pushValues("contact", `${e.detail.value}`)}
          >
            <IonSelectOption value="email">Email</IonSelectOption>
            <IonSelectOption value="teams">Phone</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem lines="full">
          <IonLabel position="floating">
            When is the best time to contact you?
          </IonLabel>
          <IonInput
            type="text"
            value={values.contactTime}
            onIonChange={(e) => pushValues("contactTime", `${e.detail.value}`)}
          ></IonInput>
        </IonItem>
        <IonItem lines="full">
          <IonLabel position="floating">Please enter your name</IonLabel>
          <IonInput
            type="text"
            value={values.name}
            required
            onIonChange={(e) => pushValues("name", `${e.detail.value}`)}
          ></IonInput>
        </IonItem>
        <IonItem lines="full">
          <IonLabel position="floating">
            Please enter your email address
          </IonLabel>
          <IonInput
            type="email"
            inputmode="text"
            value={values.email}
            required
            onIonChange={(e) => pushValues("email", `${e.detail.value}`)}
          ></IonInput>
        </IonItem>

        <IonItem lines="full">
          <IonLabel position="floating">
            {" "}
            Please enter your phone number
          </IonLabel>
          <IonInput
            inputmode="tel"
            type="text"
            value={values.phone}
            onIonChange={(e) => pushValues("phone", `${e.detail.value}`)}
          ></IonInput>
        </IonItem>

        <IonRow>
          <IonCol>
            <IonButton type="submit" color="medium" expand="block">
              Submit
            </IonButton>
          </IonCol>
        </IonRow>
      </form>
    </div>
  );
}

export default EmailSupportForm;
