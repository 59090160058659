import { IonCol, IonGrid, IonRow } from "@ionic/react";

function Home() {
  return (
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div>
              <iframe
                title="wcs"
                src="https://windhorsecommunityservices.com/"
                width="1100"
                height="800"
              />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
  );
}

export default Home;
