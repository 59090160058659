import React from "react";
import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonImg,
  IonContent,
} from "@ionic/react";
import EmailSupportForm from "./EmailSupportForm";

function EncryptedEmailSupport() {
  return (
    <IonContent className="ion-padding">
      <h3>Introduction</h3>
      <p>
        Windhorse Community Services has safegaurds to keep protected
        information private and secure. Communication with external parties is
        facilitated using only secure methods as required by our privacy policy.
        To ensure client privacy encrypted email is required for all outgoing
        external email from WCS.{" "}
      </p>
      <br></br>
      <p></p>
      <h5>To find more information please choose an article below:</h5>
      <br></br>
      <IonAccordionGroup>
      <IonAccordion value="first">
          <IonItem slot="header" color="light">
            <IonLabel><strong>Video: </strong>How to Access a WCS Encrypted Email</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
          <iframe width="1024" height="800" src="https://www.youtube.com/embed/7eQsy2z3ZSM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </IonAccordion>
        <IonAccordion value="second">
          <IonItem slot="header" color="light">
            <IonLabel>How do I open a protected message?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>
              Protected messages allows the Windhorse Community Services to send
              encrypted email messages and set specific permissions on a
              message, such as Do Not Forward or Do Not Print. If you receive a
              protected email message sent to your Microsoft 365 account in
              Outlook 2016 or Outlook on the web, the message should open like
              any other message. You'll see a banner at the top of the message
              that informs you of any restrictions on the message. If you're
              using a different email account or email program, you may receive
              a notification that you've received a protected message and need
              to take additional action.
            </p>
            <div className="images">
              <IonImg
                src="/assets/EmailFromServer.PNG"
                alt="Email example"
              ></IonImg>
            </div>
            <br></br>
            <h1>Reading a protected message with Gmail or Yahoo</h1>
            <h4>Desktop/Browser:</h4>
            <ol>
              <li>Select "Read Secure Message" to read your message.</li>
              <li>Select Sign in with Google/Yahoo.</li>
              <li>
                You'll be redirected to the Gmail/Yahoo sign-in page. Once you sign
                in, select Allow.
              </li>
              <li>
                Your protected message will display in a new browser tab. You
                won't be able to view the protected message in the Gmail window.
              </li>
            </ol>
            <h3>Mobile app:</h3>
            <ol>
              <li>Tap "Read Secure Message" to read your message.</li>
              <li>Tap Sign in with... and sign in to your email account.</li>
              <li>
                If you get a request for permissions, tap Yes or Allow to view
                the message.
              </li>
            </ol>
            <div className="images">
              <strong>Sign in page example:</strong>
              <IonImg
                src="/assets/SignInGoogle.PNG"
                alt="Email example"
              ></IonImg>
            </div>
            <br></br>
            <div className="images">
              <strong>Email example:</strong>
              <IonImg
                src="/assets/EmailSample.PNG"
                alt="Email example"
              ></IonImg>
            </div>
            <br></br>
            <strong>**Please note, you may not be required to log-in when reading emails if you are previously signed into the service provider</strong>
            <br></br>
            <br></br>
            <h1>Reading a protected message with a single-use code</h1>
            <p>
              Some email clients and services can't automatically open protected
              messages. If you have an email account with some
              Internet Service Providers, you'll need to obtain a single-use code
              to read the message. Optionaly you may set your comptuer as
              private and allow you to remain signed in for 12 hours.
            </p>
            <h4>Desktop/Browser:</h4>
            <ol>
              <li>Tap "Read Secure Message" to read your message.</li>
              <li>
                You'll be redirected to a page where you can sign in and receive
                a single-use code.{" "}
              </li>
              <li>
                Check your email for the single-use code. Enter the code in the
                browser window, then select Continue to read your message.
              </li>
            </ol>
            <h4>Mobile app:</h4>
            <ol>
              <li>
                Tap "Read Secure Message" to read your message then sign in with
                a single-use code.
              </li>
              <li>
                You'll be redirected to a page where you can sign in and receive
                a single-use code.{" "}
              </li>
              <li>Check your email for the single-use code and copy it.</li>
              <li>
                Enter the code in your browser, then select Continue to read
                your message.
              </li>
            </ol>
            <div className="images">
              <strong>One time passcode example:</strong>
              <IonImg
                src="/assets/OneTimeCode.PNG"
                alt="Email example"
              ></IonImg>
            </div>
            <br></br>
            <h1>
              Reading a protected message with a Microsoft 365 email account in
              Outlook or Outlook on the web
            </h1>
            <h4>Desktop/Browser:</h4>
            <ol>
              <li>
                If you're using a Microsoft 365 email account in Outlook 2016 or
                Outlook on the web, you shouldn't have to do anything special to
                read your message.
              </li>
            </ol>
            <h4>Mobile app:</h4>
            <ol>
              <li>
                If you have a Microsoft 365 account and you're using the Outlook
                mobile app, the message should just open.
              </li>
            </ol>
          </div>
        </IonAccordion>

        <IonAccordion value="third">
          <IonItem slot="header" color="light">
            <IonLabel>How do I open a attachments?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>
              Attachments are automatically encrypted and decrypted with the
              email they are attached to. By clicking the attachment inside of
              the email a new window will open allowing you to view the
              attachment. Additional options such as downloading{" "}
              <strong>may</strong> be available at the discretion of WCS.
            </p>
            <p>
              With the attachment viewer open you have the option to show the
              email which will open the email text side by side with the
              attachment.
            </p>
            <h4>Desktop/Browser:</h4>
            <ol>
              <li>
                Clicking on the message will open a window to view the
                attachment.
              </li>
              <li>
                Optionaly you may click the dropdown to choose a preview or
                download option (if available)
              </li>
            </ol>
            <h4>Mobile app:</h4>
            <ol>
              <li>
                Clicking on the message will open a window to view the
                attachment.
              </li>
              <li>
                Optionaly you may click the dropdown to choose a preview or
                download option (if available)
              </li>
            </ol>
            <div className="images">
              <strong>Email with attachment:</strong>
              <IonImg
                src="/assets/EmailSampleWAttachment.PNG"
                alt="Email example"
              ></IonImg>
            </div>
            <div className="images">
              <strong>Email default viewer:</strong>
              <IonImg
                src="/assets/EmailSampleWAttachmentView.PNG"
                alt="Email example"
              ></IonImg>
            </div>
          </div>
        </IonAccordion>

        <IonAccordion value="fourth">
          <IonItem slot="header" color="light">
            <IonLabel>How do I open a reply to a message?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <p>
              Emails received will have a default button to reply to the sender
              or replay all to reply to all individuals on the email chain. If
              you use the reply buttons from an encrypted email received from a
              WCS sender, your reply will be automatically encrypted. It is
              important to note, sending an email directly to a WCS recipient
              directly from your email account is <strong>not encrypted</strong>{" "}
              and secured. Please use the reply options available here to send
              secure email.{" "}
            </p>
            <div className="images">
              <strong>Email default viewer:</strong>
              <IonImg
                src="/assets/EmailSample.PNG"
                alt="Email example"
              ></IonImg>
            </div>
          </div>
        </IonAccordion>

        <IonAccordion value="fifth">
          <IonItem slot="header" color="light">
            <IonLabel>
              How do I get support from Windhorse Community Services?
            </IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            <h1>Submit support request</h1>
            <EmailSupportForm />
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </IonContent>
  );
}
export default EncryptedEmailSupport;
