import { IonContent } from "@ionic/react";

function PrivacyPolicy() {
  return (
    <IonContent className="ion-padding">
    <div>
      <header>
        Operations Revised: 12/01/2021 Version 1.0 Issued: 12/01/2021
      </header>
      <h1>PURPOSE</h1>
      <p>
        This Privacy Policy describes the types of personal information
        collected by Windhorse Community Services, Inc. (“WCS”), how we use such
        information, and to whom and under what circumstances we may disclose
        it. This Privacy Policy applies to WCS’ website,
        www.windhorsecommunityservices.com (the “Site”), WCS’ Electronic Health
        Records application (the “EHR App”), Microsoft Office 365 (the
        “Platform”), and any personal information obtained when you call, email,
        message, text, or otherwise communicate with WCS. By accessing the Site
        or otherwise interacting with us, you agree to this Privacy Policy. WCS
        can modify this Privacy Policy at any time. We encourage you to
        periodically review our Privacy Policy to stay informed about how we are
        using the information we collect.
      </p>
      <h1>POLICY</h1>
      <h4>Information We Collect from WCS’s Website:</h4>
      <p>
        For each visitor to our Site, we may collect information such as the
        domain name of the website the visitor came from, the e-mail addresses
        of those who post messages to our bulletin board (if given), the e-mail
        addresses of those who communicate with us via e-mail, aggregate
        information on what pages visitors visit, information volunteered by a
        visitor, such as survey information, participation in chats or
        discussion boards, and/or site registrations. WCS does not sell or rent
        your information for commercial purposes.
      </p>
      <h4>Client Protected Health Information (PHI):</h4>
      <p>
        As a licensed home health care provider, WCS will collect PHI using
        secure email and secure messaging services provided by Microsoft. All
        PHI is stored and used on WCS’ EHR App and Platform. WCS does not sell
        or rent your PHI for commercial purposes.
      </p>
      <h4>Secure Messaging and Secure Email:</h4>
      <p>
        If a user elects to communicate with employees of WCS using use our text
        messaging service and our email service to communicate with clinicians
        and/or administrators, we will ask them for their email address and
        name. Email addresses and any other identifying information are stored
        by Microsoft, who is partnering with WCS, for the purpose of providing a
        secure enterprise platform which includes secure messaging and email.
        Demographic information will be used for the purpose of personalizing
        the secure messages and emails a user receives. Page 2 of 4 We may
        collect this information and use it for marketing and communications
        purposes, internal review, research, and to improve the content of our
        Web page, but personally identifiable information is generally not
        shared with other organizations for commercial purposes.
      </p>
      <h4>Cookies:</h4>
      <p>
        To help make our Site and Platform more responsive to the needs of our
        visitors, we invoke a standard feature found in browser software, called
        a "cookie", to assign each visitor a unique, random number, a sort of
        anonymous user ID that resides on your computer. The cookie identifies
        the computer that a visitor uses to access the Site but cannot access or
        read information from your computer’s hard drive. You can delete or
        disable cookies at any time by changing the preferences in most web
        browsers. For our internal purposes, we gather date, time, browser type,
        navigation history and IP address of all visitors to our site. We use
        this information for our internal security audit log, trend analysis and
        system administration, and to gather broad demographic information about
        our user base for aggregate use.
      </p>
      <h4>Admissions Department Screening:</h4>
      <p>
        WCS’ Admissions Department will collect PHI which includes personal
        information regarding your mental health conditions, such as depression
        or anxiety. We also collect voluntary demographic information, including
        age range, gender, race/ethnicity, household income, state, and zip
        code. We may provide deidentified or aggregated data to universities,
        health providers or others for research purposes, in all cases taking
        reasonable steps to ensure that such data cannot be associated with any
        particular individual.
      </p>
      <h4>Release of Information (ROI):</h4>
      <p>
        We collect and disclose PHI to those individuals, agencies, and external
        providers that the client authorizes through a signed Release of
        information (ROI). ROIs are securely stored, used, and transmitted.
      </p>
      <h4>WCS Clinicians Collect and Document PHI While Providing Services:</h4>
      <p>
        Through the course of services being provided to clients, clinical staff
        will document using various types of notes (i.e., plans of care, basic
        attendance notes, psychotherapy notes, etc.) the client’s experience,
        progress, assessments, etc. which are a part of the client’s clinical
        chart. These notes are securely used, stored, and transmitted securely.
      </p>
      <h4>Other Ways We May Disclose Your Information:</h4>
      <p>
        We may also disclose information collected from and about you as
        follows: (1) to our related companies and service providers, to perform
        a business, professional or technical support function for us; (2) to
        our marketing partners, advertisers or other third parties, who may
        contact you regarding the mission and opportunities with WCS; (3) as
        necessary if we believe that there has been a violation of the Site
        Terms of Use or of our rights or the rights of any third party; (4) to
        respond to legal processes (such as a search warrant, subpoena or court
        Page 3 of 4 order) and provide information to law enforcement agencies
        or in connection with an investigation on matters related to public
        safety, as permitted by law, or otherwise as required by law; and (5) in
        the event that WCS or substantially all of its assets are acquired, your
        personal information may be one of the transferred assets. We may also
        disclose your personal information with your express consent.
      </p>
      <p>
        Please note that if you voluntarily submit any personal information for
        posting on the Sites, such as a review or a blog post, the information
        becomes publicly available and can be collected and used by others, so
        you should use care before posting information about yourself online.
      </p>
      <h4>Links To Other Web Sites:</h4>
      <p>
        When WCS’ Site includes links to other sites, WCS does not assume any
        responsibility or liability for any communications or materials
        available at the sites to which it links, including responsibility or
        liability for their accuracy. No link on WCS’ Site is a referral or
        endorsement of either the linked-to entity or any product or service.
      </p>
      <h4>Privacy Policy Changes:</h4>
      <p>
        If our information practices change at some time in the future, we will
        post the policy changes to our Site to notify you of these changes and
        may provide you with the ability to opt out of these new uses. If you
        are concerned about how your information is used, you should check back
        at our Web site periodically.
      </p>
      <h4>Security Information:</h4>
      <p>
        With respect to security: We endeavor to make sure that our Site,
        Platform, EHR App, and services provided by WCS protect personal
        information that we receive are secure. When we transfer and receive
        certain types of sensitive information such as financial information and
        clinical history, we will provide secure means to collect that
        information, such as encrypted email and secure messaging. No Site,
        Platform, EHR App, or online transaction is completely and perfectly
        secure, and you should exercise care and judgment in ensuring that you
        follow good security practices, including accessing our Site, Platform,
        and EHR App through a secure device and internet connection.
      </p>
      <p>
        All sensitive information provided by individuals to WCS is considered
        confidential, unless otherwise noted. Information is never sold or
        traded. If WCS wishes to use any confidential information provided, the
        provider will be contacted, and a ROI form outlining its use will be
        provided. Individuals retain the right to request WCS remove their
        information from all mailings, both online and on paper at any time, and
        WCS will endeavor to remove the information in a reasonable manner, but
        delays between any such request and the discontinuation of related
        mailings can occur.
      </p>
      <h4>Your Options Concerning Personal Information:</h4>
      <p>
        We provide all visitors to our Site, Platform, and EHR App with the
        ability to make certain requests regarding their personal information.
        For example, you can request:
      </p>
      <ol>
        <li>
          information regarding the categories and specific information we have
          collected about you;
        </li>
        <li>
          correction of information we have about you that is incorrect; and
        </li>
        <li> deletion of information we have collected about you.</li>
      </ol>
      <p>
        Your exercise of these rights will have no adverse effect on the price
        and quality of our goods or services. When you exercise these rights and
        submit a request to us, we may verify your identity by asking you for
        your email address, telephone number, or other information. We also may
        use a third-party verification provider to verify your identity.
      </p>
      <h4>Opt-Out for Email and Regular Mail: </h4>
      <p>If you provide us with your email or postal address, we may contact you unless you explicitly 
opt out by calling us, mailing us, or emailing us using the contact information provided below. 
under Contact Us.</p>
      <h4>Visitors From Outside The United States—Cross-Border Transfer:
</h4>
      <p>If you are visiting the Site, Platform, or EHR App from outside the United States, your 
information may be transferred to, stored, and processed in the United States or other countries 
in accordance with this Privacy Policy. The data protection and other applicable laws of the
United States or other countries may not be as comprehensive as those laws or regulations in 
your country or may otherwise differ from the data protection or consumer protection laws in 
your country. Your information may be available to government authorities under lawful orders 
and law applicable in such jurisdictions. By using the Site, Platform, and EHR App and/or 
providing personal information to us, you consent to the transfer of your information to our 
facilities as described in this Privacy Policy.</p>
      <h4>Contact Us:
</h4>
      <p>If you have questions or concerns about this Privacy Policy or how we collect and use personal 
information, you can contact us:</p>
<br>
</br>
Email: security@wcsboulder.com
Telephone: (303) 786-9314 x126
    </div>
    </IonContent>
  );
}

export default PrivacyPolicy;
