import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  archiveOutline,
  archiveSharp,
  chatboxEllipsesOutline,
  chatboxEllipsesSharp,
  documentLockOutline,
  documentLockSharp,
  heartOutline,
  heartSharp,
  homeOutline,
  homeSharp,
  lockClosedOutline,
  lockClosedSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  saveOutline,
  saveSharp,
  trashOutline,
  trashSharp,
} from "ionicons/icons";
import "./Menu.css";
import { Login } from "@microsoft/mgt-react";
import { IonImg } from "@ionic/react";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  disabled: boolean;
}

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/page/Home",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    disabled: false
  },
  {
    title: "Encrypted Email Support",
    url: "/page/Encrypted Email Support",
    iosIcon: mailOutline,
    mdIcon: mailSharp,
    disabled: false
  },
  {
    title: "Microsoft Teams Support",
    url: "/page/Microsoft Teams Support",
    iosIcon: chatboxEllipsesOutline,
    mdIcon: chatboxEllipsesSharp,
    disabled: true
  },
  {
    title: "Privacy Policy",
    url: "/page/Privacy Policy",
    iosIcon: saveOutline,
    mdIcon: saveSharp,
    disabled: false
  },
  {
    title: "Privacy Practices",
    url: "/page/Privacy Practices",
    iosIcon: documentLockOutline,
    mdIcon: documentLockSharp,
    disabled: true
  },
  {
    title: "WCS Data Protection",
    url: "/page/WCS Data Protection",
    iosIcon: lockClosedOutline,
    mdIcon: lockClosedSharp,
    disabled: true
  },
  {
    title: "Inbox",
    url: "/page/Inbox",
    iosIcon: mailOutline,
    mdIcon: mailSharp,
    disabled: true
  },
  {
    title: "Outbox",
    url: "/page/Outbox",
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp,
    disabled: true
  },
  {
    title: "Favorites",
    url: "/page/Favorites",
    iosIcon: heartOutline,
    mdIcon: heartSharp,
    disabled: true
  },
  {
    title: "Archived",
    url: "/page/Archived",
    iosIcon: archiveOutline,
    mdIcon: archiveSharp,
    disabled: true
  },
  {
    title: "Trash",
    url: "/page/Trash",
    iosIcon: trashOutline,
    mdIcon: trashSharp,
    disabled: true
  },
];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <div className="logoImage">
            <IonImg
              src="https://windhorsecommunityservices.com/wp-content/uploads/2021/03/Windhorse_Logo_hrz.png"
              alt="The windhorse logo"
            ></IonImg>
          </div>
          <IonListHeader>Client Portal</IonListHeader>
          <div className="App">
            <header>
              { !location ? <Login />: null}
            </header>
          </div>
          {appPages.map((appPage, index, disabled) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                {appPage.disabled === false ? <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                  disabled={appPage.disabled}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>: null}
                
              </IonMenuToggle>
            );
          })}
        </IonList>        
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
